import { loadItem } from "./Store";
import { BUILDING, COUNTRY, MD5_SALT_2, QR_SEPARATOR } from "../Constant";
import { generatePassAppHash } from "./md5Hash";
import moment from "moment-timezone";
import person from "assets/img/person_placeholder.png";

export function findData(id, category) {
  const items = loadItem(category);

  if (items) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.id === id) {
        return item;
      }
    }
  }

  return null;
}

export function findEstablishment(pass, companies, countries) {
  if (pass.company) {
    return companies.find((company) => company.id === pass.company);
  } else {
    return countries.find((country) => country.id === pass.country);
  }
}

export function isPendingUser(user) {
  return user.firstname === "-pending-";
}

export function getUserFullname(user) {
  if (!user) return "";
  return user.firstname + " " + user.lastname;
}

export function getUserEstablishments(user, companies, countries) {
  let establishments = [];

  let userCountries = user.residences || [];
  let userCompanies = (user.workplaces || []).map(
    (workplace) => workplace.company
  );

  if (countries && companies) {
    countries.forEach((country) => {
      if (userCountries.includes(country.id)) {
        establishments.push(country);
      }
    });

    userCompanies.forEach((company, index) => {
      companies.forEach((c) => {
        if (c.id === company) {
          establishments.push({
            ...c,
            ...user.workplaces[index],
          });
        }
      });
    });
  }

  return establishments;
}

export function isCountry(establishment) {
  return establishment.type === BUILDING || establishment.type === COUNTRY;
}

export function createInviteDynamicLink(pass, user) {
  let passMd5 = generatePassAppHash(`${pass.id}${QR_SEPARATOR}`, MD5_SALT_2);

  let url = `${process.env.REACT_APP_ENDUSER_URL}landingPass?id=${pass.id}`;
  let fallbackUrl = `${process.env.REACT_APP_ENDUSER_URL}invite-${pass.id}.${passMd5}`;

  let dynamicLink = `https://ndv8y.app.goo.gl/?isi=1270315054&ifl=${fallbackUrl}&afl=${fallbackUrl}&ibi=com.predictionlabs.passappuser&imv=1.0.1&link=${url}&ofl=${fallbackUrl}&apn=com.predictionlabs.passappuser`;

  if (pass.calendar) {
    // Si es pase permanente...

    let allow = false;

    if (pass.country && user.residencesInfo) {
      user.residencesInfo.forEach((c) => {
        if (c.id === pass.country && c.allowPermanentPassesForExpress) {
          allow = true;
        }
      });
    }

    if (pass.company && user.workplaces) {
      user.workplaces.forEach((c) => {
        if (c.company === pass.company && c.allowPermanentPassesForExpress) {
          allow = true;
        }
      });
    }

    if (!allow) {
      dynamicLink = `https://ndv8y.app.goo.gl/?isi=1270315054&ibi=com.predictionlabs.passappuser&imv=1.0.1&link=${url}&apn=com.predictionlabs.passappuser`;
    }
  }

  return dynamicLink;
}

export function getUserPhotoUrl(user) {
  if (user && user.facePhoto) {
    return process.env.REACT_APP_IMAGES_URL + user.facePhoto;
  } else {
    return person;
  }
}

export function getPassUser(pass, friends, token) {
  if (pass.guests && pass.guests.length > 0) {
    return friends.find((friend) => friend.id === pass.guests[0]); // Pases como host
  } else if (pass.host !== getCurrentUserId(token)) {
    return friends.find((friend) => friend.id === pass.host); // Pases como invitado
  }
  return null;
}

export function getPassUsers(pass, friends, token) {
  let guests = [];
  if (pass.guests && pass.guests.length > 0) {
    for (let i in pass.guests) {
      guests.push(friends.find((friend) => friend.id === pass.guests[i])); // Pases como host
    }
  } else if (pass.host !== getCurrentUserId(token)) {
    guests = [friends.find((friend) => friend.id === pass.host)]; // Pases como invitado
  }

  return guests;
}

export function getUserWorkplace(user, id) {
  if (user.workplaces) {
    for (let i = 0; i < user.workplaces.length; i++) {
      const workplace = user.workplaces[i];
      if (workplace.company === id) {
        return workplace;
      }
    }
  }
  return null;
}

export function getCurrentUserId(token) {
  return token.split(":")[0];
}

export function onFieldChange(ev) {
  this.setState({
    [ev.target.name]: ev.target.value,
  });
}

export function isValidDni(dni) {
  return !new RegExp(/[\W_]+/g).test(dni);
}

export function sanitizeDni(dni) {
  return dni.replace(/[\W_]+/g, "");
}

export function unmaskPhone(phone) {
  return phone.replace(/[^0-9]*/g, ""); // fix spaces
}

export function getTimezoneLocal() {
  return moment.tz.guess();
}

export function getDatetimeShort(datetime) {
  return datetime.replace("T", " ").substr(0, 19);
}

export function getBrowserLanguage() {
  let defaultLanguage = "es";
  let allowedLanguages = ["es", /*'en', */ "pt"];

  let userLang =
    navigator.language || navigator.userLanguage || defaultLanguage;

  if (userLang.includes("-")) {
    userLang = userLang.split("-")[0];
  }

  if (allowedLanguages.indexOf(userLang) !== -1) {
    return userLang;
  }

  return defaultLanguage;
}
export function getLocaleLanguage() {
  let defaultLanguage = "es";
  let userLang =
    navigator.language || navigator.userLanguage || defaultLanguage;
  if (userLang.slice(0, 2) === "es") {
    defaultLanguage = "es";
  } else {
    if (userLang.slice(0, 2) === "pt") {
      defaultLanguage = "pt-br";
    } else {
      defaultLanguage = "en-ca";
    }
  }
  return defaultLanguage;
}

export function extractContentHtml(s) {
  var span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
}

export function generateUUID() {
  var d = new Date().getTime();
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}
