import React, { Component } from "react";
// @material-ui/core
import { withStyles, createStyles } from "@material-ui/core/styles";
import Button from "components/common/CustomButtons/Button.js";
import { withTranslation } from "react-i18next";
import { IconButton, TextField, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Loader from "utils/Loader";
import PassAppApi from "services/PassAppApi";
import SnackbarMessage from "utils/SnackbarMessage";
import CloseIcon from "@material-ui/icons/Close";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.sendRequestPassword = this.sendRequestPassword.bind(this);
    this.handleTempPasswordChange = this.handleTempPasswordChange.bind(this);
    this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(
      this
    );
    this.confirmPassword = this.confirmPassword.bind(this);
  }

  getInitialState() {
    return {
      email: "",
      loading: false,
      showPasswordInputs: false,
      tempPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      error: false,
    };
  }

  handleEmailChange(ev) {
    this.setState({
      email: ev.target.value,
    });
  }

  handleTempPasswordChange(ev) {
    this.setState({
      tempPassword: ev.target.value,
    });
  }

  handleNewPasswordChange(ev) {
    this.setState({
      newPassword: ev.target.value,
      error: false,
    });
  }

  handleConfirmPasswordChange(ev) {
    this.setState({
      confirmNewPassword: ev.target.value,
      error: false,
    });
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState(this.getInitialState());
    }
  }

  confirmPassword(ev) {
    ev.preventDefault();
    const { t, onClose } = this.props;
    const { email, newPassword, tempPassword, confirmNewPassword } = this.state;

    if (newPassword === confirmNewPassword) {
      this.setState({
        loading: true,
      });

      let fields = {
        newPassword: newPassword,
        oldPassword: tempPassword,
      };

      PassAppApi.confirmPassword(email, fields)
        .then(() => {
          onClose();
          SnackbarMessage.show("success", t("password_changed"));
        })
        .catch((err) => {
          let error = err ? err.toString() : t("error_updating_user");

          SnackbarMessage.show("error", error);
          this.setState({
            loading: false,
          });
        });
    } else {
      this.setState({
        error: true,
      });
    }
  }

  sendRequestPassword(ev) {
    ev.preventDefault();
    const { t } = this.props;
    const { email } = this.state;

    this.setState({
      loading: true,
    });

    PassAppApi.recoverPassword(email)
      .then(() => {
        this.setState({
          loading: false,
          showPasswordInputs: true,
        });
      })
      .catch((err) => {
        let error = err ? err.toString() : t("error_sending_email");

        SnackbarMessage.show("error", error);
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const { onClose, t, open } = this.props;
    const {
      email,
      loading,
      confirmNewPassword,
      newPassword,
      showPasswordInputs,
      tempPassword,
      error,
    } = this.state;

    let errorText = error ? t("passwords_dont_match") : "";

    let recoverFields = (
      <div>
        <Typography variant="subtitle1" gutterBottom>
          {t("recover_password_email_sent")}
        </Typography>
        <TextField
          type="password"
          fullWidth
          label={t("temp_password")}
          value={tempPassword}
          onChange={this.handleTempPasswordChange}
          margin="normal"
          required
          variant="outlined"
        />
        ,
        <TextField
          fullWidth
          label={t("new_password")}
          value={newPassword}
          onChange={this.handleNewPasswordChange}
          margin="normal"
          required
          error={error}
          variant="outlined"
          type="password"
          helperText={errorText}
        />
        ,
        <TextField
          fullWidth
          type="password"
          required
          error={error}
          label={t("confirm_password")}
          value={confirmNewPassword}
          onChange={this.handleConfirmPasswordChange}
          margin="normal"
          helperText={errorText}
          variant="outlined"
        />
      </div>
    );

    return (
      <div className="forgot-password">
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          onClose={onClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <form
            className="center"
            onSubmit={
              showPasswordInputs
                ? this.confirmPassword
                : this.sendRequestPassword
            }
          >
            <DialogTitle id="customized-dialog-title">
              {t("recover_password")}
              <IconButton
                edge="end"
                color="inherit"
                onClick={onClose}
                aria-label="close"
                style={{ position: "absolute", right: "20px", top: "8px" }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className="dialog" dividers>
              {!showPasswordInputs && (
                <TextField
                  id="outlined-name"
                  fullWidth
                  label={t("enter_email")}
                  value={email}
                  onChange={this.handleEmailChange}
                  margin="normal"
                  variant="outlined"
                  required
                  type="email"
                />
              )}

              {showPasswordInputs && recoverFields}
            </DialogContent>
            <DialogActions className="dialog-buttons">
              <div className="loader">
                <Loader className="submit" visible={loading} />
              </div>
              {!showPasswordInputs && (
                <Button
                  disabled={loading}
                  color="primary"
                  type="submit"
                  className="add"
                  round
                >
                  {t("recover_password")}
                </Button>
              )}
              {showPasswordInputs && (
                <Button
                  disabled={loading}
                  color="primary"
                  type="submit"
                  className="add"
                  round
                >
                  {t("accept")}
                </Button>
              )}
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}

const useStyles = createStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    width: "100%",
    marginBottom: "15px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default withTranslation()(withStyles(useStyles)(ForgotPassword));
