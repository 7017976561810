import {
  STATE_VERIFICATION,
  STATE_PHOTOS,
  PENDING_NAME,
  STATE_LOGGED_IN,
  STATE_NEEDS_TO_JOIN,
  DEFAULT_PHONE_MASK,
  US_CODE,
  ARG_CODE,
  ARG_MASK,
  US_MASK,
} from "../Constant";
import { unmaskPhone } from "./Utils";
import history from "../history";

export function hasWorkplace(company, user) {
  if (isPendingWorkplace(company, user)) return true;
  if (!user.workplaces) return false;

  for (let i = 0; i < user.workplaces.length; i++) {
    const workplace = user.workplaces[i];
    if (workplace.company === company.id) {
      return true;
    }
  }

  return false;
}

export function hasResidence(country, user) {
  if (isPendingResidence(country, user)) return true;
  if (!user.residences) return false;

  for (let i = 0; i < user.residences.length; i++) {
    const residence = user.residences[i];
    if (residence === country.id) {
      return true;
    }
  }

  return false;
}

export function isPendingWorkplace(company, user) {
  if (!user.requestedWorkplaces) return false;

  for (let i = 0; i < user.requestedWorkplaces.length; i++) {
    const workplace = user.requestedWorkplaces[i];
    if (workplace.company === company.id) {
      return true;
    }
  }

  return false;
}

export function isPendingResidence(country, user) {
  if (!user.requestedResidences) return false;

  for (let i = 0; i < user.requestedResidences.length; i++) {
    const residence = user.requestedResidences[i];
    if (residence === country.id) {
      return true;
    }
  }

  return false;
}

export function getUserState(user) {
  if (!user.facePhoto || user.facePhoto === "undefined") {
    return STATE_PHOTOS;
  }
  if (user.requires2FA) {
    return STATE_VERIFICATION;
  }
  if (user.birthdate && (user.firstname !== PENDING_NAME && user.firstname)) {
    return STATE_LOGGED_IN;
  }
  if (!user.requestedResidences && !user.requestedWorkplaces) {
    return STATE_NEEDS_TO_JOIN;
  }
  return STATE_LOGGED_IN;
}

export function redirectionRegister(user) {
  if (!user) {
    history.push("/");
    return;
  }

  if (Object.keys(user).length === 0 && user.constructor === Object) {
    history.push("/");
    return;
  }
  switch (getUserState(user)) {
    case STATE_VERIFICATION:
      history.push("/register/verify");
      break;
    case STATE_NEEDS_TO_JOIN:
      history.push("/register/joinEstablishment");
      break;
    case STATE_PHOTOS:
      history.push("/register/uploadUserPhotos");
      break;
    default:
      break;
  }
}

export function parsePhoneNumber(phone, s) {
  let old = unmaskPhone(phone);
  let str = unmaskPhone(s);

  let mask = DEFAULT_PHONE_MASK;
  let newValue = "";

  if (s.startsWith(US_CODE)) {
    mask = US_MASK;
  } else if (s.startsWith(ARG_CODE)) {
    mask = ARG_MASK;
  }

  let i = 0;

  for (let k = 0; k < mask.length; k++) {
    const m = mask[k];
    if (
      m !== "X" &&
      ((m !== "#" && str.length > old.length) ||
        (m !== "#" && str.length < old.length && str.length !== i))
    ) {
      newValue += m;
      continue;
    }

    if (i < str.length) {
      if (m !== "X" || str[i] !== "0") newValue += str[i];
    } else {
      break;
    }

    i++;
  }

  return newValue;
}
