import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Admin from "../../layouts/Admin";

const Home = () => {
  const { user, otherCompany } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if ("Android" in window) {
      try {
        window.Android.checkContactPermission();
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  return (
    <Admin
      companyManager={user.companyManager}
      parkingManager={user.parkingManager}
      deskManager={user.deskManager}
      charterManager={user.charterManager}
      spaceManager={user.spaceManager}
      otherCompany={otherCompany}
    />
  );
};

export default Home;
