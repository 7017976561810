import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import { Hidden } from "@material-ui/core";
import { HANDLE_MENU_MOBILE } from "actions/types";
import logo from "assets/img/logo.png";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import Navbar from "components/common/Navbars/Navbar.js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PassesMail from "views/Passes/PassesMail";
import Profile from "../components/Profile/Profile";
import Sidebar from "../components/Sidebar/Sidebar";
import ConfirmationDialogStatic from "../components/common/ConfirmationDialog/ConfirmationDialogStatic";
import NavbarApp from "../components/common/Navbars/NavbarApp";
import useRoutes from "../hooks/useRoutes";
import { controlRoute } from "../services/roleControls.js";
import BottomMenu from "../views/BottomMenu/BottomMenu";

let ps;
const useStyles = makeStyles(styles);
export default function Admin({ ...rest }) {
  const { t } = useTranslation();
  const {
    companyManager,
    parkingManager,
    deskManager,
    charterManager,
    otherCompany,
    spaceManager,
  } = rest;
  const { routes } = useRoutes();
  const { user, mobileOpen } = useSelector((state) => state.authReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [color] = React.useState("green");
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    const resizeFunction = () => {
      if (window.innerWidth >= 960) {
        dispatch({
          type: HANDLE_MENU_MOBILE,
          payload: false,
        });
        if (ps) {
          ps.destroy();
        }
      }
      if (window.innerWidth <= 1280) {
        if (navigator.platform.indexOf("Win") > -1) {
          ps = new PerfectScrollbar(mainPanel.current, {
            suppressScrollX: true,
            suppressScrollY: false,
          });
          document.body.style.overflow = "hidden";
        }
      } else if (ps) {
        ps.destroy();
      }
    };
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        if (ps) {
          ps.destroy();
        }
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  useEffect(() => {
    if (otherCompany?.isExternalUser && otherCompany?.isAllowFaceId) onDetect();
  }, [otherCompany?.isAllowFaceId]);
  useEffect(() => {
    if (user?.isDefaultFacePhoto && otherCompany?.isAllowFaceId) onDetect();
  }, [otherCompany?.isAllowFaceId, user?.isDefaultFacePhoto]);
  const onDetect = () => {
    if (!user?.facePhoto) {
      ConfirmationDialogStatic.show({
        title: t("facephoto_dialog_message"),
        labelNo: t("facephoto_dialog_confirm"),
        labelYes: t("facephoto_dialog_cancel"),
        onAccept: () => history.push("/home/updatePhoto"),
      });
    }
  };
  return (
    <>
      {otherCompany.requiresEmailValidation ? (
        <PassesMail />
      ) : (
        <div className={classes.wrapper}>
          <Sidebar
            routes={routes}
            companyManager={companyManager}
            deskManager={deskManager}
            charterManager={charterManager}
            spaceManager={spaceManager}
            logoText={t("app_name")}
            logo={logo}
            handleDrawerToggle={() =>
              dispatch({
                type: HANDLE_MENU_MOBILE,
                payload: false,
              })
            }
            color={color}
            open={mobileOpen}
            {...rest}
          />
          <div className={classes.mainPanel} ref={mainPanel}>
            {otherCompany?.isExternalUser ? (
              <NavbarApp />
            ) : (
              <Navbar routes={routes} {...rest} />
            )}
            <div className={classes.content}>
              <div
                className={`${
                  classes.subContent
                } ${otherCompany?.isExternalUser && classes.background}`}
              >
                <Switch>
                  {!otherCompany?.isExternalUser && (
                    <Route path="/home/profile" component={Profile} />
                  )}
                  {routes.map((prop, key) => {
                    if (prop.layout === "/home" && prop?.component) {
                      if (
                        !controlRoute(
                          prop.action,
                          companyManager,
                          parkingManager,
                          deskManager,
                          charterManager,
                          spaceManager,
                          otherCompany
                        )
                      ) {
                        return null;
                      }
                      return (
                        <Route
                          path={prop.layout + prop.path}
                          component={prop.component}
                          key={key}
                        />
                      );
                    }
                    return null;
                  })}
                  <Redirect from="/home" to="/home/passes" />
                </Switch>
              </div>
              {otherCompany?.isExternalUser && (
                <Hidden smUp>
                  <BottomMenu />
                </Hidden>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
