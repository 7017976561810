import PassesPage from "views/Passes/Passes";
import Tutorials from "views/Tutorials/Tutorials.js";
import CompanyStaff from "views/CompanyStaff/CompanyStaff.js";
import RoomReservation from "views/RoomReservation/RoomReservation";
import NotificationsPage from "views/Notifications/Notifications.js";
import Parkings from "views/Parkings/Parkings";
import ValidatePassesPage from "views/ValidatePasses/ValidatePasses";
import JoinEstablishment from "../components/JoinEstablishment/JoinEstablishment";
import MyEstablishments from "../views/MyEstablishments/MyEstablishments";
import Desks from "../views/Desks/Desks";
import MyQR from "../views/MyQR/MyQR";
import PassRequest from "../views/PassRequest/PassRequest";
import AccessEvent from "../views/AccessEvent/AccessEvent";
import Dashboard from "@material-ui/icons/Dashboard";
import HelpRoundedIcon from "@material-ui/icons/HelpRounded";
import Notifications from "@material-ui/icons/Notifications";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import DateRangeIcon from "@material-ui/icons/DateRange";
import RoomIcon from "@material-ui/icons/Room";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { useTranslation } from "react-i18next";
import QrCodeIcon from "../assets/img/QrCodeIcon";
import PassRequestIcon from "../assets/img/PassRequestIcon";
import DesktopMacIcon from "../assets/img/DesktopMacIcon";
import SpaceIcon from "../assets/img/SpaceIcon";
import { useSelector } from "react-redux";
import PassesApp from "../views/Passes/PassesApp";
import NotificationsApp from "../views/Notifications/NotificationsApp";
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import UpdateFacePhoto from "../views/UpdateFacePhoto/UpdateFacePhoto";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import Charters from "views/Charters/Charters";
import Spaces from "views/Spaces/Spaces";

const routePath = {
  PASSES: "/passes",
  QR: "/qr",
  REQUEST: "/request",
  NOTIFICATION: "/notifications",
  VALIDATE_PASSES: "/validate-passes",
  ACCESS_EVENT: "/access-event",
  COMPANY_STAFF: "/company-staff",
  AUDITORIUMS: "/auditoriums",
  PARKINGS: "/parkings",
  SPACE_RESERVATION: "/spaceReservations",
  CHARTERS: "/charters",
  DESK: "/desks",
  JOIN_ESTABLISHMENT: "/joinEstablishment",
  MY_ESTABLISHMENT: "/myEstablishments",
  UPDATE_FACEPHOTO: "/updatePhoto",
};
const useRoutes = () => {
  const { t } = useTranslation();
  const { otherCompany } = useSelector((state) => state.authReducer);
  const routes = [
    {
      path: routePath.PASSES,
      name: otherCompany?.isExternalUser
        ? t("bottom_menu_myguest")
        : t("my_passes"),
      icon: Dashboard,
      component: otherCompany?.isExternalUser ? PassesApp : PassesPage,
      layout: "/home",
    },
    {
      path: routePath.QR,
      name: t("menu_my_qr"),
      icon: QrCodeIcon,
      component: MyQR,
      layout: "/home",
      action: "myQR",
    },
    {
      path: routePath.REQUEST,
      name: t("pass_request_menu"),
      icon: PassRequestIcon,
      component: PassRequest,
      layout: "/home",
      action: "passRequest",
    },
    {
      path: routePath.NOTIFICATION,
      name: t("my_notifications"),
      icon: Notifications,
      component: otherCompany?.isExternalUser
        ? NotificationsApp
        : NotificationsPage,
      layout: "/home",
    },
    {
      path: routePath.UPDATE_FACEPHOTO,
      name: t("facephoto_menu"),
      icon: PhotoCameraOutlinedIcon,
      component: UpdateFacePhoto,
      layout: "/home",
      action: "updateFacephoto",
    },
    {
      path: routePath.VALIDATE_PASSES,
      name: t("validate_passes"),
      icon: CheckCircleOutlineIcon,
      component: ValidatePassesPage,
      layout: "/home",
      action: "validatePasses",
    },
    {
      path: routePath.ACCESS_EVENT,
      name: t("access_event_menu"),
      icon: AssignmentIndIcon,
      component: AccessEvent,
      layout: "/home",
      action: "accessEvent",
    },
    {
      path: routePath.COMPANY_STAFF,
      name: t("company_staff"),
      icon: SupervisorAccountIcon,
      component: CompanyStaff,
      layout: "/home",
      action: "companyStaff",
    },
    {
      path: routePath.AUDITORIUMS,
      name: t("room_reseration"),
      icon: DateRangeIcon,
      component: RoomReservation,
      layout: "/home",
      action: "roomReservation",
    },
    {
      path: routePath.PARKINGS,
      name: t("parking_reservation"),
      icon: DirectionsCarIcon,
      component: Parkings,
      layout: "/home",
      action: "Parkings",
    },
    {
      path: routePath.CHARTERS,
      name: t("charter_reservation"),
      icon: AirportShuttleIcon,
      component: Charters,
      layout: "/home",
      action: "Charters",
    },
    {
      path: routePath.SPACE_RESERVATION,
      name: t("space_reservation"),
      icon: SpaceIcon,
      component: Spaces,
      layout: "/home",
      action: "Space",
    },
    {
      path: routePath.DESK,
      name: t("desks_reservation"),
      icon: DesktopMacIcon,
      component: Desks,
      layout: "/home",
      action: "Desks",
    },
    {
      path: routePath.JOIN_ESTABLISHMENT,
      name: t("join_establishment"),
      icon: AddCircleIcon,
      component: JoinEstablishment,
      layout: "/home",
      action: "joinEstablishment",
    },
    {
      path: routePath.MY_ESTABLISHMENT,
      name: t("my_establishments"),
      icon: RoomIcon,
      component: MyEstablishments,
      layout: "/home",
      action: "myEstablishments",
    },
    {
      name: t("video_tutorials"),
      icon: HelpRoundedIcon,
      component: Tutorials,
      href: "https://passapp.net/faq_esp.php",
      action: "tutorials",
    },
  ];
  return { routes, routePath };
};
export default useRoutes;
